<template>
    <div>
        <!--begin::Head-->
        <div class="kt-login__head">
      <span class="kt-login__signup-label">¿Ya estás registrado?</span
      >&nbsp;&nbsp;
            <router-link
                    class="kt-link kt-login__signup-link"
                    :to="{ name: 'login' }"
            >
                ¡Ingresa!
            </router-link>
        </div>
        <!--end::Head-->

        <!--begin::Body-->
        <div class="kt-login__body">
            <!--begin::Signin-->
            <div class="kt-login__form">
                <div class="kt-login__title">
                    <h3>Crea tu cuenta</h3>
                    <h6>Favor completa los datos de registro</h6>
                </div>
                <div
                        role="alert"
                        v-bind:class="{ show: errors }"
                        class="alert fade alert-danger"
                        v-if="errors"
                >
                    <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error[0] }}
                    </div>
                </div>

                <!--begin::Form-->
                <b-form class="kt-form" @submit.stop.prevent="onSubmit">
                    <b-form-group
                            id="example-input-group-0"
                            label=""
                            label-for="example-input-0"
                    >
                        <b-form-input
                                id="example-input-0"
                                name="example-input-0"
                                v-model="$v.form.firstname.$model"
                                :state="validateState('firstname')"
                                aria-describedby="input-0-live-feedback"
                                placeholder="Nombre"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-0-live-feedback">
                            El nombre es requerido.
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group
                            id="example-input-group-00"
                            label=""
                            label-for="example-input-00"
                    >
                        <b-form-input
                                id="example-input-00"
                                name="example-input-00"
                                v-model="$v.form.lastname.$model"
                                :state="validateState('lastname')"
                                aria-describedby="input-00-live-feedback"
                                placeholder="Apellido"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-00-live-feedback">
                            El apellido es requerido.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                            id="example-input-group-1"
                            label=""
                            label-for="example-input-1"
                    >
                        <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="$v.form.email.$model"
                                :state="validateState('email')"
                                aria-describedby="input-1-live-feedback"
                                placeholder="Correo electrónico"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                            La dirección de email es requerida.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                            id="example-input-group-2"
                            label=""
                            label-for="example-input-2">
                        <b-form-input
                                type="password"
                                id="example-input-2"
                                name="example-input-2"
                                v-model="$v.form.password.$model"
                                :state="validateState('password')"
                                aria-describedby="input-2-live-feedback"
                                placeholder="Clave"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-2-live-feedback">
                            Password es requerido.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                            id="example-input-group-3"
                            label=""
                            label-for="example-input-2">
                        <b-form-input
                                type="password"
                                id="example-input-3"
                                name="example-input-3"
                                v-model="$v.form.c_password.$model"
                                :state="validateState('c_password')"
                                aria-describedby="input-3-live-feedback"
                                placeholder="Confirma tu clave"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-3-live-feedback">
                            Confirmación de password debe conincidir.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!--begin::Action-->
                    <div class="kt-login__actions">
                        <b-button
                                v-on:click="$router.push('login')"
                                class="btn btn-secondary btn-elevate kt-login__btn-primary"
                        >
                            {{ $t("AUTH.GENERAL.BACK_BUTTON") }}
                        </b-button>

                        <b-button
                                type="submit"
                                id="kt_submit"
                                class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
                        >
                            {{ $t("AUTH.REGISTER.TITLE") }}
                        </b-button>
                    </div>
                    <!--end::Action-->
                </b-form>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
        <!--end::Body-->
    </div>
</template>

<script>
    import {REGISTER} from "@/store/auth.module";
    import {LOGOUT} from "@/store/auth.module";

    import {validationMixin} from "vuelidate";
    import {email, required, minLength, sameAs} from "vuelidate/lib/validators";

    export default {
        mixins: [validationMixin],
        name: "register",
        data() {
            return {
              errors:  null,
                form: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    password: "",
                    c_password: ""
                }
            };
        },
        validations: {
            form: {
                firstname: {
                    required,
                    minLength: minLength(5)
                },
                lastname: {
                    required,
                    minLength: minLength(5)
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
                c_password: {
                    sameAsPassword: sameAs('password')
                }
            }
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            resetForm() {
                this.form = {
                    firstname: null,
                    lastname: null,
                    email: null,
                    password: null,
                    c_password: null
                };

                this.$nextTick(() => {
                    this.$v.$reset();
                });
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                const firstname = this.$v.form.firstname.$model;
                const lastname = this.$v.form.lastname.$model;
                const email = this.$v.form.email.$model;
                const password = this.$v.form.password.$model;
                const c_password = this.$v.form.c_password.$model;

                this.$store.dispatch(LOGOUT);
                const submitButton = document.getElementById("kt_submit");
                submitButton.classList.add("kt-spinner");
                this.$store.dispatch(REGISTER, {
                    email,
                    password,
                    firstname,
                    lastname,
                    c_password
                })
                    .then(() => this.$router.push('home'))
                    .catch((err) => {
                      this.errors = err.data ? err.data.errors : [['Un error ah courrido']];
                    })
                    .finally(() => {
                        submitButton.classList.remove("kt-spinner");
                    });
            }
        },
        computed: {
            backgroundImage() {
                return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
            }
        }
    };
</script>
